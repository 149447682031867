<template>
  <section class="product-top product-top-category container-full">
    <div class="background">
      <div class="container">
        <div class="row">
          <div class="col-xs col-md-6">
            <HeightSelector :options="options" @input="goToCategory" />
          </div>

          <div class="col-xs col-md-6 col-lg-5 hidden-xs" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SliderCategories from './SliderCategories'
import HeightSelector from './HeightSelector'

import { isServer } from '@vue-storefront/core/helpers'

export default {
  mixins: [SliderCategories],
  components: {
    HeightSelector
  },
  async created () {
    await this.$store.dispatch('category-next/fetchMenuCategories', {
      skipCache: isServer,
      key: 'id',
      value: this.categories.map(a => a.id)
    })
  },
  computed: {
    options () {
      return this.loadedCategories.map(c => {
        return {
          label: c.name,
          value: c
        }
      })
    }
  },
  methods: {
    goToCategory (category) {
      this.$router.push(this.categoryLink(category))
    }
  }
}
</script>
