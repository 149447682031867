<template>
  <section class="category-compare container-full">
    <div class="background">
      <div class="container">
        <h2>
          <img src="/assets/logo/logo-zaun24-compare.svg" alt="ZAUN24 | Vergleich"> <span>Doppelstabmattenzaun</span>
        </h2>

        <div class="compare-table-wrapper">
          <div class="compare-table">
            <div class="table--row">
              <div class="table--head table-head-element-top" />

              <div class="table--column">
                <div class="table--product-image">
                  <img src="/assets/content/product-main-image.png">
                </div>

                <div class="table--product-head">
                  DS Premium / DS Profi
                </div>
                <div class="table--product-subhead">
                  UNSER BESTSELLER
                </div>
              </div>
              <div class="table--column">
                <div class="table--product-image">
                  <img src="/assets/content/kat_profi_500x600-neu.jpg">
                </div>

                <div class="table--product-head">
                  DS Secure
                </div>
                <div class="table--product-subhead">
                  Der sichere Zaun ohne Spitzen
                </div>
              </div>
              <div class="table--column">
                <div class="table--product-image">
                  <img src="/assets/content/eko_gruen_550_3.jpg">
                </div>

                <div class="table--product-head">
                  DS EKO 656
                </div>
                <div class="table--product-subhead">
                  DER WIRTSCHAFTLICHE
                </div>
              </div>
              <div class="table--column">
                <div class="table--product-image">
                  <img src="/assets/content/ds_200_kategorie_600x500_2.jpg">
                </div>

                <div class="table--product-head">
                  DS 200 S
                </div>
                <div class="table--product-subhead">
                  DER VIELSEITIGE
                </div>
              </div>
            </div>

            <div class="table--row table--sub-head">
              <div class="table--head" />
              <div class="table--column">
                <strong>ZAUNELEMENTE</strong>
              </div>
              <div class="table--column">
                <strong>ZAUNELEMENTE</strong>
              </div>
              <div class="table--column">
                <strong>ZAUNELEMENTE</strong>
              </div>
              <div class="table--column">
                <strong>ZAUNELEMENTE</strong>
              </div>
            </div>

            <div class="table--row">
              <div class="table--head">
                Höhe in CM
              </div>

              <div class="table--column">
                <div class="table--product-width">
                  63/83/103/123/143/163/183/203
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-width">
                  60/80/100/120/140/160/183/203
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-width">
                  83/103/123/143/163/183/203
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-width">
                  63/83/103/123/143/163/183/203
                </div>
              </div>
            </div>

            <div class="table--row">
              <div class="table--head">
                Breite in CM
              </div>

              <div class="table--column">
                <div class="table--product-width">
                  251cm (kürzbar)
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-width">
                  200 und 251cm (kürzbar)
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-width">
                  251cm (kürzbar)
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-width">
                  201 cm (kürzbar)
                </div>
              </div>
            </div>

            <div class="table--row">
              <div class="table--head">
                Drahtstärken
              </div>

              <div class="table--column">
                <div class="table--product-thickness">
                  6/5/6 mm mit Spitzen <strong>(DS Premium)</strong><br>
                  8/6/8 mm mit Spitzen <strong>(DS Profi)</strong><br>
                  (Montage wahlweise oben/unten)
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-thickness">
                  6/5/6 oder 8/6/8 mm ohne Spitzen
                  *entspricht Zaunbau-DIN 18320 und
                  Empfehlung der Gütergemeinschaft Metallzauntechnik e.V. (bis Höhe 160cm)
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-thickness">
                  6/5/6 mm mit Spitzen
                  <br>(Montage wahlweise oben/unten)
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-thickness">
                  6/5/6 oder 8/6/8 mm mit Spitzen<br>
                  (Montage wahlweise oben/unten)
                  <br>
                </div>
              </div>
            </div>

            <div class="table--row">
              <div class="table--head">
                Oberfläche
              </div>

              <div class="table--column">
                <div class="table--product-surface">
                  <div class="icon-surface icon-surface__galvanised" />
                  <div class="icon-surface icon-surface__grey" />
                  <div class="icon-surface icon-surface__green" />
                  <div class="icon-surface icon-surface__color_circle" />
                </div>
                <div class="table--product-properties table--product-properties-small">
                  im Tauchbad feuerverzinkt nach EN ISO
                  1461 <strong>und</strong> auf Wunsch <strong>zusätzlich</strong> hoch-<br>
                  wertig pulverbeschichtet, Farben grün
                  RAL 6005 oder anthrazitgrau RAL 7016<br>
                  Sonderfarben möglich
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-surface">
                  <div class="icon-surface icon-surface__galvanised" />
                  <div class="icon-surface icon-surface__grey" />
                  <div class="icon-surface icon-surface__green" />
                </div>
                <div class="table--product-properties table--product-properties-small">
                  im Tauchbad feuerverzinkt nach EN ISO
                  1461 oder verzinkt nach EN 10211-2 und
                  hochwertig pulverbeschichtet, Farben grün
                  RAL 6005 oder anthrazitgrau RAL 7016
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-surface">
                  <div class="icon-surface icon-surface__galvanised" />
                  <div class="icon-surface icon-surface__grey" />
                  <div class="icon-surface icon-surface__green" />
                </div>
                <div class="table--product-properties table--product-properties-small">
                  im Tauchbad feuerverzinkt nach EN ISO
                  1461 oder verzinkt nach EN 10211-2 und
                  hochwertig pulverbeschichtet, Farben grün
                  RAL 6005 oder anthrazitgrau RAL 7016
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-surface">
                  <div class="icon-surface icon-surface__galvanised" />
                  <div class="icon-surface icon-surface__grey" />
                  <div class="icon-surface icon-surface__green" />
                </div>
                <div class="table--product-properties table--product-properties-small">
                  im Tauchbad feuerverzinkt nach EN ISO<br>
                  1461 oder verzinkt nach EN 10211-2 und<br>
                  hochwertig pulverbeschichtet, Farben grün<br>
                  RAL 6005 oder anthrazitgrau RAL 7016
                </div>
              </div>
            </div>

            <div class="table--row table--sub-head">
              <div class="table--head" />
              <div class="table--column">
                <strong>Pfosten</strong>
              </div>
              <div class="table--column">
                <strong>Pfosten</strong>
              </div>
              <div class="table--column">
                <strong>Pfosten</strong>
              </div>
              <div class="table--column">
                <strong>Pfosten</strong>
              </div>
            </div>

            <div class="table--row">
              <div class="table--head">
                Eigenschaften
              </div>

              <div class="table--column">
                <div class="table--product-properties">
                  Vierkantrohr 60 x 40 mm
                  <br>Wandstärke 2,0 mm
                  <br><br>Pfostenkappe aus Aluminium
                  <br>mit Überstand (farbig beschichtet)
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-properties">
                  Vierkantrohr 60 x 40 mm
                  <br>Wandstärke 1,5 mm
                  <br><br>Pfostenkappe aus Kunststoff
                  <br>mit Überstand (farbig passend)
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-properties">
                  Vierkantrohr 60 x 40 mm
                  <br>Wandstärke 1,5 mm und 2,0 mm
                  <br><br>Pfostenkappe aus Kunststoff
                  <br>mit Überstand (farbig passend)
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-properties">
                  Vierkantrohr 60 x 40 mm
                  <br>Wandstärke 1,5 mm und 2,0 mm
                  <br><br>Pfostenkappe aus Kunststoff
                  <br>mit Überstand (farbig passend)
                </div>
              </div>
            </div>

            <div class="table--row">
              <div class="table--head">
                Matten-Befestigung
              </div>

              <div class="table--column">
                <div class="table--product-properties">
                  Kunststoffhalter (genietet)
                  <br>Abstand alle 20 cm
                  <br>mit stabiler Stahl-Abdeckschiene
                  <br>40/5 mm und Edelstahlschrauben
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-properties">
                  Kunststoffhalter (genietet)
                  <br>Abstand alle 20-60 cm
                  <br>mit stabiler Stahl-Abdeckschiene
                  <br>40/4 mm und Edelstahlschrauben
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-properties">
                  Kunststoffhalter (genietet)
                  <br>Abstand 20 und 40 cm
                  <br>mit Edelstahlklemmhaltern und -schrauben
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-properties">
                  Kunststoffhalter (genietet)
                  <br>Abstand 20 und 40 cm
                  <br>mit Edelstahlklemmhaltern und -schrauben
                </div>
              </div>
            </div>

            <div class="table--row">
              <div class="table--head">
                Oberfläche
              </div>

              <div class="table--column">
                <div class="table--product-surface">
                  <div class="icon-surface icon-surface__galvanised" />
                  <div class="icon-surface icon-surface__grey" />
                  <div class="icon-surface icon-surface__green" />
                  <div class="icon-surface icon-surface__color_circle" />
                </div>
                <div class="table--product-properties table--product-properties-small">
                  im Tauchbad feuerverzinkt nach EN ISO
                  1461 <strong>und</strong> auf Wunsch <strong>zusätzlich</strong> hoch-<br>
                  wertig pulverbeschichtet, Farben grün
                  RAL 6005 oder anthrazitgrau RAL 7016<br>
                  Sonderfarben möglich
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-surface">
                  <div class="icon-surface icon-surface__galvanised" />
                  <div class="icon-surface icon-surface__grey" />
                  <div class="icon-surface icon-surface__green" />
                </div>
                <div class="table--product-properties table--product-properties-small">
                  im Tauchbad feuerverzinkt nach EN ISO
                  1461 oder verzinkt nach EN 10211-2 und
                  hochwertig pulverbeschichtet, Farben grün
                  RAL 6005 oder anthrazitgrau RAL 7016
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-surface">
                  <div class="icon-surface icon-surface__galvanised" />
                  <div class="icon-surface icon-surface__grey" />
                  <div class="icon-surface icon-surface__green" />
                </div>
                <div class="table--product-properties table--product-properties-small">
                  im Tauchbad feuerverzinkt nach EN ISO
                  1461 oder verzinkt nach EN 10211-2 und
                  hochwertig pulverbeschichtet, Farben grün
                  RAL 6005 oder anthrazitgrau RAL 7016
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-surface">
                  <div class="icon-surface icon-surface__galvanised" />
                  <div class="icon-surface icon-surface__grey" />
                  <div class="icon-surface icon-surface__green" />
                </div>
                <div class="table--product-properties table--product-properties-small">
                  im Tauchbad feuerverzinkt nach EN ISO
                  1461 oder verzinkt nach EN 10211-2 und
                  hochwertig pulverbeschichtet, Farben grün
                  RAL 6005 oder anthrazitgrau RAL 7016
                </div>
              </div>
            </div>

            <div class="table--row">
              <div class="table--head">
                Fazit
              </div>

              <div class="table--column">
                <div class="table--product-properties">
                  Unser Bester: Höchste Qualität
                  Made in Germany by ALBERTS
                  Bester Korrosionsschutz und
                  zertifizierte Stabilität gem. DIN
                  EN 10223-7 mit 15 Jahren Garantie
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-properties">
                  Entspricht der Zaunbau-Norm ATV
                  DIN 18320 u. den Empfehlungen
                  der Gütegemeinschaft Metallzaun-
                  technik e.V., höchste Sicherheit für
                  Kinder und Tiere ohne überstehende
                  Spitzen
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-properties">
                  Wirtschaftlicher Zaun mit gutem
                  Preis-Leistungsverhältnis
                </div>
              </div>

              <div class="table--column">
                <div class="table--product-properties">
                  200cm Zaunelemente für
                  optimale Stabiliät. Bei Gefälle
                  / Steigung im Grundstücks-
                  verlauf besonders geeignet
                </div>
              </div>
            </div>

            <div class="table--row">
              <div class="table--head" />

              <div class="table--column" v-if="categoryLink(1499)">
                <div class="table--product-action">
                  <router-link
                    :to="categoryLink(1499)"
                    class="btn btn-primary"
                  >
                    Artikel ansehen
                  </router-link>
                </div>
              </div>

              <div class="table--column" v-if="categoryLink(24753)">
                <div class="table--product-action">
                  <router-link
                    :to="categoryLink(24753)"
                    class="btn btn-primary"
                  >
                    Artikel ansehen
                  </router-link>
                </div>
              </div>

              <div class="table--column" v-if="categoryLink(1503)">
                <div class="table--product-action">
                  <router-link
                    :to="categoryLink(1503)"
                    class="btn btn-primary"
                  >
                    Artikel ansehen
                  </router-link>
                </div>
              </div>

              <div class="table--column" v-if="categoryLink(20774)">
                <div class="table--product-action">
                  <router-link
                    :to="categoryLink(20774)"
                    class="btn btn-primary"
                  >
                    Artikel ansehen
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'

export default {
  name: 'Zaun24Compare',
  props: {
    categories: {
      type: null,
      required: true
    }
  },
  methods: {
    categoryLink (id) {
      const category = this.$store.state['category-next'].menuCategories.find((item) => item.id === id)
      if (category) {
        return formatCategoryLink(category, currentStoreView().storeCode)
      }
    }
  }
}
</script>
