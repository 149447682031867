<template>
  <div id="category">
    <breadcrumbs />

    <section class="category-intro">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div v-if="typeof getCurrentCategory.description === 'string'">
              <div
                v-if="getCurrentCategory.description.includes('pagebuilder')"
                :id="getCurrentCategory.description.includes('pagebuilder') ? 'html-body' : ''"
                :class="getCurrentCategory.description.includes('pagebuilder') ? 'pagebuilder-container' : ''"
                class="category-intro-main-container"
              >
                <div class="category-intro-main">
                  <h1 class="category-title">
                    {{ categoryName }}
                  </h1>
                  <div class="category-intro-main-description">
                    <div
                      v-html="getCurrentCategory.description"
                      class="category-intro-main-description-container"
                    />
                  </div>
                </div>
              </div>
              <category-description-accordion
                :heading="categoryName"
                :content="getCurrentCategory.description"
                v-else
              />
            </div>
            <category-description-accordion
              :heading="categoryName"
              :content="getCurrentCategory.description"
              v-else
            />
          </div>
        </div>
      </div>
    </section>

    <category-height-selector
      v-if="childCategories.length > 0 && !hasActiveFilters && getCurrentCategory.landing_page == '23'"
      :categories="childCategories"
      :is-grid="true"
      :columns="(canShowSidebar) ? 3 : 4"
    />

    <section class="category-mobile-filter" v-if="canShowSidebar">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <button
              class="btn btn-primary mobile-filters-button"
              @click="openFilters"
            >
              <span v-if="hasActiveFilters">{{ $t('Edit filters') }}</span>
              <span v-else>{{ $t('Filters') }}</span>
            </button>
            <div
              class="sidebar__header__clear"
              @click="resetAllFilters"
              v-show="hasActiveFilters"
            >
              <i class="material-icons cl-accent mr5">
                cancel
              </i>
              {{ $t('Clear filters') }}
            </div>
          </div>
        </div>

        <div class="start-xs mobile-filters" v-show="mobileFilters">
          <div class="close-container">
            <button class="close" @click="closeFilters" />
          </div>
          <sidebar class="mobile-filters-body" :filters="getAvailableFilters" @changeFilter="changeFilter" />
          <div class="divider" />
          <div class="filter-button-container">
            <button class="btn__filter btn btn-primary" @click="closeFilters">
              Jetzt filtern
            </button>
          </div>
        </div>
      </div>
    </section>

    <div class="category-filtered-listing container">
      <div class="row">
        <div class="col-xs-12 col-md-3" v-if="canShowSidebar">
          <div class="category-filters">
            <sidebar :filters="getAvailableFilters" @changeFilter="changeFilter" />
          </div>
        </div>
        <div :class="(canShowSidebar) ? 'col-xs-12 col-md-9' : 'col-xs-12 col-md-12'">
          <slider-categories
            v-if="canShowCategoriesSlider"
            :categories="childCategories"
            :is-grid="true"
            :columns="(canShowSidebar) ? 3 : 4"
            :show-extra-tile="true"
          />

          <div v-if="canShowProducts">
            <h3 v-if="canShowCategoriesSlider && getCategoryProducts.length > 0">
              <template v-if="!getCurrentCategory.is_anchor">
                Unsere Produktempfehlungen
              </template>
              <template v-else>
                Unsere Produkte
              </template>
            </h3>

            <lazy-hydrate :trigger-hydration="!loading" v-if="isLazyHydrateEnabled">
              <product-listing :columns="(canShowSidebar) ? 3 : 4" :products="getCategoryProducts" />
            </lazy-hydrate>
            <product-listing v-else :columns="(canShowSidebar) ? 3 : 4" :products="getCategoryProducts" />
          </div>
        </div>
      </div>
    </div>

    <zaun24-compare
      :categories="childCategories"
      v-if="getCurrentCategory.id === 1284"
    />

    <seo-banners :id="getCurrentCategory.slider_id" v-if="getCurrentCategory.slider_id" />
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import Sidebar from '../components/core/blocks/Category/Sidebar.vue'
import ProductListing from '../components/core/ProductListing.vue'
import Breadcrumbs from '../components/core/Breadcrumbs.vue'
import { isServer } from '@vue-storefront/core/helpers'
import { Logger } from '@vue-storefront/core/lib/logger'
import { getSearchOptionsFromRouteParams } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers'
import config from 'config'
import { mapGetters } from 'vuex'
import onBottomScroll from '@vue-storefront/core/mixins/onBottomScroll'
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { htmlDecode } from '@vue-storefront/core/filters'

// Zaun 24
import SliderCategories from 'theme/components/core/blocks/Custom/SliderCategories'
import CategoryDescriptionAccordion from 'theme/components/core/blocks/Custom/CategoryDescriptionAccordion.vue'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import CategoryHeightSelector from 'theme/components/core/blocks/Custom/CategoryHeightSelector'
import Zaun24Compare from 'theme/components/core/blocks/Custom/Zaun24Compare.vue'
import SeoBanners from 'src/modules/vsf-bannerslider/components/SeoBanners.vue'

import { addRemarketingInfo } from 'src/modules/google-tag-manager/helpers/track'

const THEME_PAGE_SIZE = 50

const canShowProducts = (store, category) => {
  return store.getters['category-next/hasActiveFilters'] || category.level >= 3
}
const canShowSidebar = (category) => {
  return typeof category.path === 'string' &&
    category.path.match(/^1\/2\/1159/)
}
const canShowCategoriesSlider = (store, category) => {
  const childCategories = (typeof category.children_data !== 'undefined') ? category.children_data : []
  return childCategories.length > 0 &&
    !store.getters['category-next/hasActiveFilters'] &&
    category.landing_page !== 23
}

const composeInitialPageState = async (store, route, forceLoad = false) => {
  try {
    const filters = getSearchOptionsFromRouteParams(route.params)
    const cachedCategory = store.getters['category-next/getCategoryFrom'](route.path)
    const currentCategory = cachedCategory && !forceLoad ? cachedCategory : await store.dispatch('category-next/loadCategory', { filters })
    const pageSize = store.getters['url/isBackRoute'] ? store.getters['url/getCurrentRoute'].categoryPageSize : THEME_PAGE_SIZE

    route.query.sort = JSON.stringify([
      {
        'category.position': {
          mode: 'avg',
          order: 'asc',
          nested_path: 'category',
          nested_filter: {
            term: { 'category.category_id': currentCategory.id }
          }
        }
      }
    ]);

    if (canShowSidebar(currentCategory) || canShowProducts(store, currentCategory)) {
      store.dispatch('category-next/loadCategoryProducts', { route, category: currentCategory, pageSize })
    }
    const breadCrumbsLoader = store.dispatch('category-next/loadCategoryBreadcrumbs', { category: currentCategory, currentRouteName: currentCategory.name, omitCurrent: true })

    /* load child categories */
    if (canShowCategoriesSlider(store, currentCategory)) {
      store.dispatch('category/list', { parent: currentCategory.id })
    }

    if (isServer) await store.dispatch('bannerslider/list', {})
    if (isServer) await breadCrumbsLoader
    catalogHooksExecutors.categoryPageVisited(currentCategory)
  } catch (e) {
    Logger.error('Problem with setting Category initial data!', 'category', e)()
  }
}

export default {
  components: {
    LazyHydrate,
    ProductListing,
    Breadcrumbs,
    Sidebar,
    SliderCategories,
    CategoryDescriptionAccordion,
    CategoryHeightSelector,
    Zaun24Compare,
    SeoBanners
  },
  mixins: [onBottomScroll],
  data () {
    return {
      mobileFilters: false,
      defaultColumn: 3,
      loadingProducts: false,
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      getCurrentSearchQuery: 'category-next/getCurrentSearchQuery',
      getCategoryProducts: 'category-next/getCategoryProducts',
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCategoryProductsTotal: 'category-next/getCategoryProductsTotal',
      getAvailableFilters: 'category-next/getAvailableFilters',
      hasActiveFilters: 'category-next/hasActiveFilters'
    }),
    isLazyHydrateEnabled () {
      return config.ssr.lazyHydrateFor.includes('category-next.products')
    },
    isCategoryEmpty () {
      return this.getCategoryProductsTotal === 0
    },
    categoryName () {
      return (this.getCurrentCategory.name_alias && this.getCurrentCategory.name_alias !== '') ? this.getCurrentCategory.name_alias : this.getCurrentCategory.name
    },
    childCategories () {
      return (typeof this.getCurrentCategory.children_data !== 'undefined') ? this.getCurrentCategory.children_data : []
    },
    canShowProducts () {
      return canShowProducts(this.$store, this.getCurrentCategory)
    },
    canShowSidebar () {
      return canShowSidebar(this.getCurrentCategory)
    },
    canShowCategoriesSlider () {
      return canShowCategoriesSlider(this.$store, this.getCurrentCategory)
    },
    hasActiveFilters () {
      return this.$store.getters['category-next/hasActiveFilters']
    }
  },
  async asyncData ({ store, route, context }) { // this is for SSR purposes to prefetch data - and it's always executed before parent component methods
    if (context) context.output.cacheTags.add('category')
    await composeInitialPageState(store, route)
  },
  async beforeRouteEnter (to, from, next) {
    if (isServer) next() // SSR no need to invoke SW caching here
    else if (!from.name) { // SSR but client side invocation, we need to cache products and invoke requests from asyncData for offline support
      next(async vm => {
        vm.loading = true
        await composeInitialPageState(vm.$store, to, true)
        await vm.$store.dispatch('category-next/cacheProducts', { route: to }) // await here is because we must wait for the hydration
        vm.loading = false
      })
    } else { // Pure CSR, with no initial category state
      next(async vm => {
        vm.loading = true
        vm.$store.dispatch('category-next/cacheProducts', { route: to })
        vm.loading = false
      })
    }
  },
  updated () {
    if (this.canShowProducts && this.getCategoryProducts.length > 0) {
      addRemarketingInfo({
        ecomm_pagetype: 'category',
        ecomm_pcat: [this.getCurrentCategory.name],
        ecomm_prodid: this.getCategoryProducts.map(product => product.sku),
        ecomm_pname: this.getCategoryProducts.map(product => product.name)
      })
    }
  },
  mounted () {
    addRemarketingInfo({
      ecomm_pagetype: 'category',
      ecomm_pcat: [this.getCurrentCategory.name],
      ecomm_prodid: this.getCategoryProducts.map(product => product.sku),
      ecomm_pname: this.getCategoryProducts.map(product => product.name)
    })
  },
  methods: {
    openFilters () {
      this.mobileFilters = true
    },
    closeFilters () {
      this.mobileFilters = false
    },
    async changeFilter (filterVariant) {
      this.$store.dispatch('category-next/switchSearchFilters', [filterVariant])
    },
    columnChange (column) {
      this.defaultColumn = column
    },
    async onBottomScroll () {
      if (this.loadingProducts) return
      this.loadingProducts = true
      try {
        await this.$store.dispatch('category-next/loadMoreCategoryProducts')
      } catch (e) {
        Logger.error('Problem with fetching more products', 'category', e)()
      } finally {
        this.loadingProducts = false
      }
    },
    resetAllFilters () {
      this.$store.dispatch('category-next/resetSearchFilters')
    }
  },
  metaInfo () {
    const storeView = currentStoreView()
    const { meta_title, meta_description, name, slug } = this.getCurrentCategory
    const meta = meta_description ? [{ vmid: 'description', name: 'description', content: htmlDecode(meta_description) }] : []

    return {
      title: htmlDecode(meta_title || name),
      meta,
      link: [
        { rel: 'canonical', href: 'https://www.' + storeView.url + this.$route.path }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .category-filters {
    width: 242px;
  }

  .category-mobile-filter,
  .mobile-filters {
    display: none;
    overflow: auto;
  }

  .mobile-filters-button {
    display: none;
  }

  .mobile-sorting {
    display: none;
  }

  .sorting {
    label {
      margin-right: 10px;
    }
  }

  @media (max-width: 64em) {
    .products-list {
      max-width: 530px;
    }
  }

  @media (max-width: 770px) {
    .category-mobile-filter,
    .mobile-filters {
      display: block;
    }

    .mobile-filters-button {
      display: block;
    }

    .sorting {
      display: none;
    }

    .mobile-sorting {
      display: block;
    }

    .category-filters {
      display: none;
    }

    .product-listing {
      justify-content: center;;
    }

    .mobile-filters {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 5;
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;
    }
  }
</style>
<style lang="scss">
.product-image {
  max-height: unset !important;
}
</style>
