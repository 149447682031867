<template>
  <section class="category-information" v-if="getBanners.length">
    <div class="container">
      <div
        v-for="(banner, index) in getBanners" :key="index"
        :class="{'image-left': index % 2 === 0, 'image-right': index % 2 !== 0 }"
      >
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="category-text-image">
              <img
                v-lazy="getThumbnail(banner.image,577,289,'banner')"
                :alt="banner.name"
              >
            </div>
          </div>

          <div class="col-xs-12 col-md-6">
            <div class="category-text-text">
              <h2>{{ banner.name }}</h2>
              <div v-html="banner.caption" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BannerSlider from './BannerSlider'

const loadBannersBySlider = (store, id) => {
  return store.dispatch('bannerslider/list', { filterValues: [id], filterField: 'slider_id', includeFields: ['slider_id', 'name', 'image', 'image_alt', 'click_url', 'button_text', 'caption'] })
}
export default {
  mixins: [BannerSlider],
  async asyncData ({ store, route }) { // this is for SSR purposes to prefetch data
    await loadBannersBySlider(store, this.id)
  },
  async mounted () {
    if (this.getBannersBySlider(this.id).length === 0) {
      await loadBannersBySlider(this.$store, this.id)
    }
  }
}
</script>
