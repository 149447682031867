<template>
  <div
    class="category-intro-main-container"
    :class="accordionClasses"
  >
    <div
      class="category-intro-main"
      :style="{'max-height': maxHeight}"
    >
      <h1 class="category-title">
        {{ heading }}
      </h1>

      <div
        class="category-intro-main-description"
      >
        <div
          v-html="description"
          class="category-intro-main-description-container"
          ref="toggleElement"
        />
      </div>
    </div>

    <div
      class="category-intro-main-description-more"
      v-if="isApplicable"
      @click="toggleAccordion"
    >
      <span v-if="!isOpen">Mehr erfahren</span>
      <span v-if="isOpen">Weniger</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryDescriptionAccordion',
  props: {
    heading: {
      type: String,
      required: true,
      default: ''
    },
    content: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    toggleAccordion () {
      this.isOpen = !this.isOpen;
    }
  },
  data () {
    return {
      isOpen: false,
      recompute: 0
    }
  },
  computed: {
    maxHeight () {
      this.recompute // eslint-disable-line no-unused-expressions
      if (this.isOpen || !this.isApplicable) {
        return 'fit-content'
      }
      if (typeof document === 'undefined') {
        return 'fit-content'
      }
      if (document.querySelector('.read-more') === null) {
        return 'fit-content'
      }
      return document.querySelector('.read-more').offsetTop + 'px'
    },
    description () {
      this.recompute // eslint-disable-line no-unused-expressions
      return (this.content) ? this.content.replace('<!--more-->', '<span class="read-more"></span>') : '';
    },
    isApplicable () {
      this.recompute // eslint-disable-line no-unused-expressions
      if (!this.content) {
        return false
      }
      return this.content.indexOf('<!--more-->') > 0
    },
    accordionClasses () {
      this.recompute // eslint-disable-line no-unused-expressions
      return {
        'is-closed': !this.isOpen
      };
    }
  },
  watch: {
    description () {
      this.$nextTick(() => {
        this.recompute++;
      })
    }
  }
}
</script>
